<template>
  <div class="section d-flex justify-center align-center">
    <router-view/>
    <BaseSnackList/>
  </div>
</template>

<script>

import '@/css/authLayout.css'
import BaseSnackList from '@/components/base/BaseSnackList/BaseSnackList'
import meta from '@/components/mixins/meta'
export default {
  mixins: [meta],
  name: 'ErrorsLayout',
  components: { BaseSnackList }
}
</script>

<style scoped>

</style>
